<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="80"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </div>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <xml-message-list v-show="xmlComponent === 'XmlMessageList'"
          :quote-id="quoteId"
          :message-type="messageType"
          :page-size="pageSize"
          @view="viewXml"
          @error="handleError" />
        <xml-viewer v-if="xmlComponent === 'XmlViewer'"
          :quote-id="quoteId"
          :message-id="selectedMessageId"
          @error="handleError" />
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button v-if="xmlComponent === 'XmlViewer'"
        class="button is-primary tooltip"
        @click="back()"
        data-tooltip="Go back">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-arrow-left-bold" />
        </span>
        <span>Back</span>
      </button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Close viewer">
        <span>Close</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import XmlMessageList from './XmlMessageList'
import XmlViewer from './XmlViewer'

export default {
  name: 'XmlViewerModal',
  components: {
    BaseModalEx,
    XmlMessageList,
    XmlViewer
  },
  props: {
    value: null,
    quoteId: {
      type: String,
      default: ''
    },
    pageSize: {
      type: Number,
      default: 10
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-code'
    },
    messageType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: false,
      xmlComponent: 'XmlMessageList',
      selectedMessageId: ''
    }
  },
  validations: {},
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {},
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    close() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    },
    back() {
      this.xmlComponent = 'XmlMessageList'
    },
    async viewXml(messageId) {
      this.selectedMessageId = messageId
      this.xmlComponent = 'XmlViewer'
    },
    handleError() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
