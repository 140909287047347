import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseQuoteFilter(filter)
    const url = `webservicetransactions/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuoteFilter(filter) {
    const entityProto = require('../../assets/proto/webservicetransaction/WebServiceTransactionFilter.proto')
    const filterMessage = entityProto.WebServiceTransactionFilter.WebServiceTransactionFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getEntity(id) {
    const url = `webservicetransactions/${id}`
    const result = await FqApi.get(url)
    return result.data
  }
}
