<template>
  <div class="tile is-parent">
    <article
      id="container"
      class="tile is-child box detail-page-tile">
      <div class="field is-grouped is-grouped-right">
        <div class="control">
          <div class="select">
            <select v-model="options.theme">
              <option
                v-for="(theme , index) in themes"
                :value="theme"
                :key="index">{{ theme }}</option>
            </select>
          </div>
        </div>
        <div class="control">
          <div
            class="button tooltip"
            data-tooltip="Toggle line wrapping"
            :class="{ 'is-success' : lineWrap }"
            @click="toggleLineWrapping">
            <span class="icon">
              <i class="mdi mdi-wrap" />
            </span>
            <span>Wrap</span>
          </div>
        </div>
      </div>
      <div
        class="xml-codemirror"
        :class="{ 'is-loading-popup': isLoading }">
        <codemirror
          v-model="code"
          :options="options"
          :width="editorWidth" />
      </div>
    </article>
  </div>
</template>

<script>
import { codemirror } from '@/components/VueCodemirror'
import { CodemirrorThemes } from '@/components/VueCodemirror'
// language js
import 'codemirror/mode/xml/xml.js'
import XmlViewerService from './XmlViewerService'

export default {
  name: 'XmlViewer',
  components: {
    codemirror
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    // options: null,
    quoteId: {
      type: String,
      default: ''
    },
    messageId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      code: '',
      editorWidth: 400,
      themes: null,
      lineWrap: false,
      options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        line: true,
        matchBrackets: true,
        showCursorWhenSelecting: true,
        lineSeparator: '\n',
        lineWrapping: false,
        mode: 'application/xml',
        readOnly: true,
        theme: 'default'
      },
      isLoading: false
    }
  },
  computed: {},
  watch: {
    // code: {
    //   handler: function(newVal, oldVal) {
    //     this.$emit('input', newVal)
    //   },
    //   deep: true
    // },
    'options.theme': function(newVal, oldVal) {
      this.$setCodemirrorTheme(newVal)
    }
  },
  created() {
    if (this.$userInfo.codemirrorTheme) {
      this.options.theme = this.$userInfo.codemirrorTheme
    }
    this.themes = CodemirrorThemes
    this.getMessage()
  },
  mounted() {
    // this.code = this.value
    this.editorWidth = document.getElementById('container').clientWidth - 40
    this.$nextTick(function() {
      window.addEventListener('resize', this.resizeEditor)
    })
  },
  methods: {
    onCmReady(cm) {
      console.log('the editor is readied!', cm)
    },
    onCmFocus(cm) {
      console.log('the editor is focus!', cm)
    },
    onCmCodeChange(newCode) {
      console.log('this is new code', newCode)
      this.code = newCode
    },
    resizeEditor() {
      this.editorWidth = document.getElementById('container').clientWidth - 40
    },
    async getMessage() {
      try {
        this.isLoading = true
        const message = await XmlViewerService.getEntity(this.messageId)
        if (message.messageJson) {
          const jsonObject = JSON.parse(message.messageJson)
          this.code = JSON.stringify(jsonObject, null, 2)
          this.options.mode = 'application/json'
        } else {
          this.code = message.messageData
        }
      } catch (e) {
        this.$emit('error')
      } finally {
        this.isLoading = false
      }
    },
    toggleLineWrapping() {
      this.lineWrap = !this.lineWrap
    }
  }
}
</script>

<style lang="scss">
.xml-codemirror {
  // Height minus header and menu
  height: 60vh; //calc(100vh - 170px);
}
</style>
