/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.WebServiceTransactionFilter = (function() {

    /**
     * Namespace WebServiceTransactionFilter.
     * @exports WebServiceTransactionFilter
     * @namespace
     */
    var WebServiceTransactionFilter = {};

    WebServiceTransactionFilter.WebServiceTransactionFilterModel = (function() {

        /**
         * Properties of a WebServiceTransactionFilterModel.
         * @memberof WebServiceTransactionFilter
         * @interface IWebServiceTransactionFilterModel
         * @property {string|null} [quoteId] WebServiceTransactionFilterModel quoteId
         * @property {string|null} [webServiceType] WebServiceTransactionFilterModel webServiceType
         * @property {number|null} [messageTypeId] WebServiceTransactionFilterModel messageTypeId
         * @property {number|null} [statusId] WebServiceTransactionFilterModel statusId
         * @property {string|null} [sortColumn] WebServiceTransactionFilterModel sortColumn
         * @property {string|null} [sortOrder] WebServiceTransactionFilterModel sortOrder
         * @property {number|null} [pageIndex] WebServiceTransactionFilterModel pageIndex
         * @property {number|null} [pageSize] WebServiceTransactionFilterModel pageSize
         */

        /**
         * Constructs a new WebServiceTransactionFilterModel.
         * @memberof WebServiceTransactionFilter
         * @classdesc Represents a WebServiceTransactionFilterModel.
         * @implements IWebServiceTransactionFilterModel
         * @constructor
         * @param {WebServiceTransactionFilter.IWebServiceTransactionFilterModel=} [p] Properties to set
         */
        function WebServiceTransactionFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WebServiceTransactionFilterModel quoteId.
         * @member {string|null|undefined} quoteId
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        WebServiceTransactionFilterModel.prototype.quoteId = null;

        /**
         * WebServiceTransactionFilterModel webServiceType.
         * @member {string|null|undefined} webServiceType
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        WebServiceTransactionFilterModel.prototype.webServiceType = null;

        /**
         * WebServiceTransactionFilterModel messageTypeId.
         * @member {number|null|undefined} messageTypeId
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        WebServiceTransactionFilterModel.prototype.messageTypeId = null;

        /**
         * WebServiceTransactionFilterModel statusId.
         * @member {number|null|undefined} statusId
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        WebServiceTransactionFilterModel.prototype.statusId = null;

        /**
         * WebServiceTransactionFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        WebServiceTransactionFilterModel.prototype.sortColumn = null;

        /**
         * WebServiceTransactionFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        WebServiceTransactionFilterModel.prototype.sortOrder = null;

        /**
         * WebServiceTransactionFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        WebServiceTransactionFilterModel.prototype.pageIndex = null;

        /**
         * WebServiceTransactionFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        WebServiceTransactionFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * WebServiceTransactionFilterModel _quoteId.
         * @member {"quoteId"|undefined} _quoteId
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        Object.defineProperty(WebServiceTransactionFilterModel.prototype, "_quoteId", {
            get: $util.oneOfGetter($oneOfFields = ["quoteId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * WebServiceTransactionFilterModel _webServiceType.
         * @member {"webServiceType"|undefined} _webServiceType
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        Object.defineProperty(WebServiceTransactionFilterModel.prototype, "_webServiceType", {
            get: $util.oneOfGetter($oneOfFields = ["webServiceType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * WebServiceTransactionFilterModel _messageTypeId.
         * @member {"messageTypeId"|undefined} _messageTypeId
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        Object.defineProperty(WebServiceTransactionFilterModel.prototype, "_messageTypeId", {
            get: $util.oneOfGetter($oneOfFields = ["messageTypeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * WebServiceTransactionFilterModel _statusId.
         * @member {"statusId"|undefined} _statusId
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        Object.defineProperty(WebServiceTransactionFilterModel.prototype, "_statusId", {
            get: $util.oneOfGetter($oneOfFields = ["statusId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * WebServiceTransactionFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        Object.defineProperty(WebServiceTransactionFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * WebServiceTransactionFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        Object.defineProperty(WebServiceTransactionFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * WebServiceTransactionFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        Object.defineProperty(WebServiceTransactionFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * WebServiceTransactionFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @instance
         */
        Object.defineProperty(WebServiceTransactionFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified WebServiceTransactionFilterModel message. Does not implicitly {@link WebServiceTransactionFilter.WebServiceTransactionFilterModel.verify|verify} messages.
         * @function encode
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @static
         * @param {WebServiceTransactionFilter.IWebServiceTransactionFilterModel} m WebServiceTransactionFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebServiceTransactionFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.quoteId != null && Object.hasOwnProperty.call(m, "quoteId"))
                w.uint32(10).string(m.quoteId);
            if (m.webServiceType != null && Object.hasOwnProperty.call(m, "webServiceType"))
                w.uint32(18).string(m.webServiceType);
            if (m.messageTypeId != null && Object.hasOwnProperty.call(m, "messageTypeId"))
                w.uint32(24).int32(m.messageTypeId);
            if (m.statusId != null && Object.hasOwnProperty.call(m, "statusId"))
                w.uint32(32).int32(m.statusId);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(56).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(64).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified WebServiceTransactionFilterModel message, length delimited. Does not implicitly {@link WebServiceTransactionFilter.WebServiceTransactionFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @static
         * @param {WebServiceTransactionFilter.IWebServiceTransactionFilterModel} message WebServiceTransactionFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebServiceTransactionFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WebServiceTransactionFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {WebServiceTransactionFilter.WebServiceTransactionFilterModel} WebServiceTransactionFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebServiceTransactionFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.WebServiceTransactionFilter.WebServiceTransactionFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.quoteId = r.string();
                    break;
                case 2:
                    m.webServiceType = r.string();
                    break;
                case 3:
                    m.messageTypeId = r.int32();
                    break;
                case 4:
                    m.statusId = r.int32();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageIndex = r.int32();
                    break;
                case 8:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a WebServiceTransactionFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof WebServiceTransactionFilter.WebServiceTransactionFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {WebServiceTransactionFilter.WebServiceTransactionFilterModel} WebServiceTransactionFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebServiceTransactionFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return WebServiceTransactionFilterModel;
    })();

    return WebServiceTransactionFilter;
})();

module.exports = $root;
